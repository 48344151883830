export const trackModel = {
   album: {
      name: '',
   },
   artists: [],
   duration_ms: 0,
   id: '',
   name: '',
   uri: '',
   external_urls: {
      spotify: '',
   },
}