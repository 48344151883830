export const css = {
   alignContent : {
      baseline : 'baseline',
      center : 'center',
      end : 'end',
      firstBaseline : 'first baseline',
      flexEnd : 'flex-end',
      flexStart : 'flex-start',
      inherit : 'inherit',
      initial : 'initial',
      lastBaseline : 'last baseline',
      normal : 'normal',
      safeCenter : 'safe center',
      spaceAround : 'space-around',
      spaceBetween : 'space-between',
      spaceEvenly : 'space-evenly',
      start : 'start',
      stretch : 'stretch',
      unsafeCenter : 'unsafe center',
      unset : 'unset',
   },
   alignItems : {
      baseline : 'baseline',
      center : 'center',
      end : 'end',
      firstBaseline : 'first baseline',
      flexEnd : 'flex-end',
      flexStart : 'flex-start',
      inherit : 'inherit',
      initial : 'initial',
      lastBaseline : 'last baseline',
      normal : 'normal',
      safeCenter : 'safe center',
      start : 'start',
      stretch : 'stretch',
      unsafeCenter : 'unsafe center',
      unset : 'unset',
   },
   alignSelf : {
      auto : 'auto',
      baseline : 'baseline',
      center : 'center',
      end : 'end',
      firstBaseline : 'first baseline',
      flexEnd : 'flex-end',
      flexStart : 'flex-start',
      inherit : 'inherit',
      initial : 'initial',
      lastBaseline : 'last baseline',
      normal : 'normal',
      safeCenter : 'safe center',
      start : 'start',
      stretch : 'stretch',
      unsafeCenter : 'unsafe center',
      unset : 'unset',
   },
   all : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
   },
   background : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: url("bg.jpg") #fff`] : null,
      [`ex: url("bg.jpg") 20% 0% repeat-y fixed #fff`] : null,
      [`ex: url("bg.jpg") 20% 0% / contain repeat-y fixed content-box padding-box #fff`] : null,
   },
   backgroundAttachment : {
      fixed : 'fixed',
      inherit : 'inherit',
      local : 'local',
      scroll : 'scroll',
   },
   backgroundClip : {
      borderBox : 'border-box',
      contentBox : 'content-box',
      inherit : 'inherit',
      initial : 'initial',
      paddingBox : 'padding-box',
      unset : 'unset',
   },
   backgroundColor : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: red`] : null,
      [`ex: #ff8800`] : null,
      [`ex: rgb(255,120,0)`] : null,
      [`ex: rgba(255,120,0,0.5)`] : null,
   },
   backgroundImage : {
      inherit : 'inherit',
      initial : 'initial',
      none : 'none',
      unset : 'unset',
      [`ex: url('imageFile.jpg')`] : null,
   },
   backgroundOrigin : {
      borderBox : 'border-box',
      contentBox : 'content-box',
      inherit : 'inherit',
      initial : 'initial',
      paddingBox : 'padding-box',
      unset : 'unset',
   },
   backgroundPosition : {
      bottom : 'bottom',
      center : 'center',
      inherit : 'inherit',
      initial : 'initial',
      left : 'left',
      right : 'right',
      top : 'top',
      unset : 'unset',
      [`ex: 50px`] : null,
      [`ex: 25%`] : null,
      [`ex: 200px 100px`] : null,
      [`ex: top 10em right`] : null,
      [`ex: top 10em right 5em`] : null,
   },
   backgroundRepeat : {
      inherit : 'inherit',
      initial : 'initial',
      noRepeat : 'no-repeat',
      repeat : 'repeat',
      repeatX : 'repeat-x',
      repeatY : 'repeat-y',
      round : 'round',
      space : 'space',
      unset : 'unset',
   },
   backgroundSize : {
      auto : 'auto',
      contain : 'contain',
      cover : 'cover',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 50px`] : null,
      [`ex: 25%`] : null,
      [`ex: 100% 32px`] : null,
   },
   border : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 2px`] : null,
      [`ex: solid`] : null,
      [`ex: red`] : null,
      [`ex: 2px solid`] : null,
      [`ex: 2px red`] : null,
      [`ex: solid red`] : null,
      [`ex: 2px solid red`] : null,
   },
   borderBottom : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 2px`] : null,
      [`ex: solid`] : null,
      [`ex: red`] : null,
      [`ex: 2px solid`] : null,
      [`ex: 2px red`] : null,
      [`ex: solid red`] : null,
      [`ex: 2px solid red`] : null,
   },
   borderBottomColor : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: red`] : null,
      [`ex: #ff8800`] : null,
      [`ex: rgb(255,120,0)`] : null,
      [`ex: rgba(255,120,0,0.5)`] : null,
   },
   borderBottomLeftRadius : {
      initial : 'initial',
      separate : 'separate',
      unset : 'unset',
      [`ex: 1em`] : null,
      [`ex: 50%`] : null,
      [`ex: 10px 20px`] : null,
   },
   borderBottomRightRadius : {
      initial : 'initial',
      separate : 'separate',
      unset : 'unset',
      [`ex: 1em`] : null,
      [`ex: 50%`] : null,
      [`ex: 10px 20px`] : null,
   },
   borderBottomWidth : {
      inherit : 'inherit',
      initial : 'initial',
      medium : 'medium',
      thick : 'thick',
      thin : 'thin',
      unset : 'unset',
      [`ex: 10px`] : null,
   },
   borderCollapse : {
      collapse : 'collapse',
      inherit : 'inherit',
      initial : 'initial',
      separate : 'separate',
      unset : 'unset',
   },
   borderColor : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: red`] : null,
      [`ex: #ff8800`] : null,
      [`ex: rgb(255,120,0)`] : null,
      [`ex: rgba(255,120,0,0.5)`] : null,
      [`ex: red green`] : null,
      [`ex: red green blue`] : null,
      [`ex: red green orange`] : null,
   },
   borderLeft : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 2px`] : null,
      [`ex: solid`] : null,
      [`ex: red`] : null,
      [`ex: 2px solid`] : null,
      [`ex: 2px red`] : null,
      [`ex: solid red`] : null,
      [`ex: 2px solid red`] : null,
   },
   borderLeftColor : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: red`] : null,
      [`ex: #ff8800`] : null,
      [`ex: rgb(255,120,0)`] : null,
      [`ex: rgba(255,120,0,0.5)`] : null,
      [`ex: red green`] : null,
      [`ex: red green blue`] : null,
      [`ex: red green orange`] : null,
   },
   borderLeftStyle : {
      dashed : 'dashed',
      dotted : 'dotted',
      double : 'double',
      groove : 'groove',
      hidden : 'hidden',
      inherit : 'inherit',
      initial : 'initial',
      inset : 'inset',
      none : 'none',
      outset : 'outset',
      ridge : 'ridge',
      solid : 'solid',
      unset : 'unset',
   },
   borderLeftWidth : {
      inherit : 'inherit',
      initial : 'initial',
      medium : 'medium',
      thick : 'thick',
      thin : 'thin',
      unset : 'unset',
      [`ex: 10px`] : null,
   },
   borderImage : {
      initial : 'initial',
      separate : 'separate',
      unset : 'unset',
      [`ex: url('imageFile.jpg') 33% space`] : null,
      [`ex: url('imageFile.jpg') 33% fill / 10px 20px / 0.1 space`] : null,
   },
   borderImageOutset : {
      initial : 'initial',
      separate : 'separate',
      unset : 'unset',
      [`ex: 10px`] : null,
      [`ex: 1.2`] : null,
      [`ex: 5px 10px`] : null,
      [`ex: 1.2 0.8 0.5`] : null,
      [`ex: 5px 10px 15px 20px`] : null,
   },
   borderImageRepeat : {
      initial : 'initial',
      repeat : 'repeat',
      round : 'round',
      separate : 'separate',
      space : 'space',
      stretch : 'stretch',
      unset : 'unset',
   },
   borderImageSlice : {
      initial : 'initial',
      separate : 'separate',
      unset : 'unset',
      [`ex: 25%`] : null,
      [`ex: 13`] : null,
      [`ex: 25% 10%`] : null,
      [`ex: 13 30 7`] : null,
      [`ex: 25% 10% 5% 15%`] : null,
      [`ex: 13 30 7 1 fill`] : null,
   },
   borderImageSource : {
      inherit : 'inherit',
      none : 'none',
      [`ex: url('imageFile.jpg')`] : null,
   },
   borderImageWidth : {
      auto : 'auto',
      inherit : 'inherit',
      [`ex: 10px`] : null,
      [`ex: 25%`] : null,
      [`ex: 1.2`] : null,
      [`ex: 25% 10%`] : null,
      [`ex: 1.2 0.8 0.5`] : null,
      [`ex: 5px 10px 15px 20px`] : null,
   },
   borderRadius : {
      initial : 'initial',
      separate : 'separate',
      unset : 'unset',
      [`ex: 1em`] : null,
      [`ex: 50%`] : null,
      [`ex: 10px 20px`] : null,
   },
   borderRight : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 2px`] : null,
      [`ex: solid`] : null,
      [`ex: red`] : null,
      [`ex: 2px solid`] : null,
      [`ex: 2px red`] : null,
      [`ex: solid red`] : null,
      [`ex: 2px solid red`] : null,
   },
   borderRightColor : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: red`] : null,
      [`ex: #ff8800`] : null,
      [`ex: rgb(255,120,0)`] : null,
      [`ex: rgba(255,120,0,0.5)`] : null,
      [`ex: red green`] : null,
      [`ex: red green blue`] : null,
      [`ex: red green orange`] : null,
   },
   borderRightStyle : {
      dashed : 'dashed',
      dotted : 'dotted',
      double : 'double',
      groove : 'groove',
      hidden : 'hidden',
      inherit : 'inherit',
      initial : 'initial',
      inset : 'inset',
      none : 'none',
      outset : 'outset',
      ridge : 'ridge',
      solid : 'solid',
      unset : 'unset',
   },
   borderRightWidth : {
      inherit : 'inherit',
      initial : 'initial',
      medium : 'medium',
      thick : 'thick',
      thin : 'thin',
      unset : 'unset',
      [`ex: 10px`] : null,
   },
   borderSpacing : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 10px`] : null,
      [`ex: 10px 20px`] : null,
   },
   borderStyle : {
      dashed : 'dashed',
      dotted : 'dotted',
      double : 'double',
      groove : 'groove',
      hidden : 'hidden',
      inherit : 'inherit',
      initial : 'initial',
      inset : 'inset',
      none : 'none',
      outset : 'outset',
      ridge : 'ridge',
      solid : 'solid',
      unset : 'unset',
   },
   borderTop : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 2px`] : null,
      [`ex: solid`] : null,
      [`ex: red`] : null,
      [`ex: 2px solid`] : null,
      [`ex: 2px red`] : null,
      [`ex: solid red`] : null,
      [`ex: 2px solid red`] : null,
   },
   borderTopColor : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: red`] : null,
      [`ex: #ff8800`] : null,
      [`ex: rgb(255,120,0)`] : null,
      [`ex: rgba(255,120,0,0.5)`] : null,
      [`ex: red green`] : null,
      [`ex: red green blue`] : null,
      [`ex: red green orange`] : null,
   },
   borderTopLeftRadius : {
      initial : 'initial',
      separate : 'separate',
      unset : 'unset',
      [`ex: 1em`] : null,
      [`ex: 50%`] : null,
      [`ex: 10px 20px`] : null,
   },
   borderTopRightRadius : {
      initial : 'initial',
      separate : 'separate',
      unset : 'unset',
      [`ex: 1em`] : null,
      [`ex: 50%`] : null,
      [`ex: 10px 20px`] : null,
   },
   borderTopStyle : {
      dashed : 'dashed',
      dotted : 'dotted',
      double : 'double',
      groove : 'groove',
      hidden : 'hidden',
      inherit : 'inherit',
      initial : 'initial',
      inset : 'inset',
      none : 'none',
      outset : 'outset',
      ridge : 'ridge',
      solid : 'solid',
      unset : 'unset',
   },
   borderTopWidth : {
      inherit : 'inherit',
      initial : 'initial',
      medium : 'medium',
      thick : 'thick',
      thin : 'thin',
      unset : 'unset',
      [`ex: 10px`] : null,
   },
   borderWidth : {
      inherit : 'inherit',
      initial : 'initial',
      medium : 'medium',
      thick : 'thick',
      thin : 'thin',
      unset : 'unset',
      [`ex: 10px`] : null,
   },
   bottom : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 100px`] : null,
      [`ex: 50%`] : null,
   },
   boxShadow : {
      inherit : 'inherit',
      initial : 'initial',
      none : 'none',
      unset : 'unset',
      [`ex: inset 5px -5px 10px 10px rgba(0,0,0,0.2)`] : null,
      [`ex: 2px 2px 3px red, 4px 4px 3px blue`] : null,
   },
   captionSide : {
      bottom : 'bottom',
      inherit : 'inherit',
      initial : 'initial',
      top : 'top',
      unset : 'unset',
   },
   clear : {
      both : 'both',
      inherit : 'inherit',
      initial : 'initial',
      left : 'left',
      none : 'none',
      right : 'right',
      unset : 'unset',
   },
   clip : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: rect(10px, 50px, 30px, 10px)`] : null,
   },
   color : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: red`] : null,
      [`ex: #ff8800`] : null,
      [`ex: rgb(255,120,0)`] : null,
      [`ex: rgba(255,120,0,0.5)`] : null,
   },
   content : {
      closeQuote : 'close-quote',
      inherit : 'inherit',
      initial : 'initial',
      noCloseQuote : 'no-close-quote',
      none : 'none',
      noOpenQuote : 'no-open-quote',
      normal : 'normal',
      openQuote : 'open-quote',
      unset : 'unset',
      [`ex: 'BLOB!'`] : null,
      [`ex: url('blob.png')`] : null,
      [`ex: attr(class)`] : null,
      [`ex: counter(countername)`] : null,
      [`ex: counter(countername, lower-roman)`] : null,
      [`ex: counter(countername, ' - ', lower-roman)`] : null,
   },
   counterIncrement : {
      inherit : 'inherit',
      initial : 'initial',
      none : 'none',
      unset : 'unset',
      [`ex: countername`] : null,
      [`ex: countername 7`] : null,
      [`ex: countername 7 counter2name 3`] : null,
   },
   counterReset : {
      inherit : 'inherit',
      initial : 'initial',
      none : 'none',
      unset : 'unset',
      [`ex: countername`] : null,
      [`ex: countername 2`] : null,
      [`ex: countername 2 counter2name -5`] : null,
   },
   cursor : {
      alias : 'alias',
      allScroll : 'all-scroll',
      auto : 'auto',
      cell : 'cell',
      colResize : 'col-resize',
      contextMenu : 'context-menu',
      copy : 'copy',
      crosshair : 'crosshair',
      default : 'default',
      eResize : 'e-resize',
      ewResize : 'ew-resise',
      grab : 'grab',
      grabbing : 'grabbing',
      help : 'help',
      inherit : 'inherit',
      initial : 'initial',
      move : 'move',
      neResize : 'ne-resize',
      neswResize : 'nesw-resize',
      noDrop : 'no-drop',
      none : 'none',
      notAllowed : 'not-allowed',
      nResize : 'n-resize',
      nsResize : 'ns-resize',
      nwResize : 'nw-resize',
      nwseResize : 'nwse-resize',
      pointer : 'pointer',
      progress : 'progress',
      rowResize : 'row-resize',
      seResize : 'se-resize',
      sResize : 's-resize',
      swResize : 'sw-resize',
      text : 'text',
      unset : 'unset',
      verticalText : 'vertical-text',
      wait : 'wait',
      wResize : 'w-resize',
      zoomIn : 'zoom-in',
      zoomOut : 'zoom-out',
      [`ex: url('customPointer.png'), auto`] : null,
   },
   direction : {
      inherit : 'inherit',
      initial : 'initial',
      ltr : 'ltr',
      rtl : 'rtl',
      unset : 'unset',
   },
   display : {
      block : 'block',
      flex : 'flex',
      inherit : 'inherit',
      initial : 'initial',
      inline : 'inline',
      inlineBlock : 'inline-block',
      inlineTable : 'inline-table',
      listItem : 'list-item',
      none : 'none',
      table : 'table',
      tableCaption : 'table-caption',
      tableCell : 'table-cell',
      tableColumn : 'table-column',
      tableColumnGroup : 'table-column-group',
      tableFooterGroup : 'table-footer-group',
      tableHeaderGroup : 'table-header-group',
      tableRow : 'table-row',
      tableRowGroup : 'table-row-group',
      unset : 'unset',
   },
   emptyCells : {
      hide : 'hide',
      inherit : 'inherit',
      initial : 'initial',
      show : 'show',
      unset : 'unset',
   },
   float : {
      inherit : 'inherit',
      initial : 'initial',
      left : 'left',
      none : 'none',
      right : 'right',
      unset : 'unset',
   },
   font : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: italic small-caps bold 12px/1.5 Helvetica, Arial, sans-serif`] : null,
   },
   fontFamily : {
      cursive : 'cursive',
      fantasy : 'fantasy',
      inherit : 'inherit',
      initial : 'initial',
      monospace : 'monospace',
      sansSerif : 'sans-serif',
      serif : 'serif',
      unset : 'unset',
      [`ex: Helvetica`] : null,
      [`ex: Helvetica, Arial, sans-serif`] : null,
   },
   fontSize : {
      inherit : 'inherit',
      initial : 'initial',
      large : 'large',
      larger : 'larger',
      medium : 'medium',
      small : 'small',
      smaller : 'smaller',
      unset : 'unset',
      xLarge : 'x-large',
      xSmall : 'x-small',
      xxLarge : 'xx-large',
      xxSmall : 'xx-small',
      [`ex: 12px`] : null,
      [`ex: 150%`] : null,
   },
   fontSizeAdjust : {
      inherit : 'inherit',
      initial : 'initial',
      none : 'none',
      unset : 'unset',
      [`ex: 0.5`] : null,
   },
   fontStretch : {
      condensed : 'condensed',
      expanded : 'expanded',
      extraCondensed : 'extra-condensed',
      extraExpanded : 'extra-expanded',
      inherit : 'inherit',
      initial : 'initial',
      normal : 'normal',
      semiCondensed : 'semi-condensed',
      semiExpanded : 'semi-expanded',
      ultaCondensed : 'ultra-condensed',
      ultraExpanded : 'ultra-expanded',
      unset : 'unset',
   },
   fontStyle : {
      inherit : 'inherit',
      initial : 'initial',
      italic : 'italic',
      normal : 'normal',
      oblique : 'oblique',
      unset : 'unset',
   },
   fontSynthesis : {
      inherit : 'inherit',
      initial : 'initial',
      none : 'none',
      style : 'style',
      unset : 'unset',
      weight : 'weight',
   },
   fontVariant : {
      inherit : 'inherit',
      initial : 'initial',
      normal : 'normal',
      smallCaps : 'small-caps',
      unset : 'unset',
   },
   fontWeight : {
      _100 : 100,
      _200 : 200,
      _300 : 300,
      _400 : 400,
      _500 : 500,
      _600 : 600,
      _700 : 700,
      _800 : 800,
      _900 : 900,
      bold : 'bold',
      bolder : 'bolder',
      inherit : 'inherit',
      initial : 'initial',
      lighter : 'lighter',
      normal : 'normal',
      unset : 'unset',
   },
   height : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 600px`] : null,
      [`ex: 25%`] : null,
   },
   left : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 100px`] : null,
      [`ex: 50%`] : null,
   },
   letterSpacing : {
      inherit : 'inherit',
      initial : 'initial',
      normal : 'normal',
      unset : 'unset',
      [`ex: 0.1em`] : null,
   },
   lineHeight : {
      inherit : 'inherit',
      initial : 'initial',
      normal : 'normal',
      unset : 'unset',
      [`ex: 1.2`] : null,
      [`ex: 1.2em`] : null,
      [`ex: 120%`] : null,
   },
   listStyle : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: square`] : null,
      [`ex: url('bullet.png')`] : null,
      [`ex: inside`] : null,
      [`ex: square url('bullet.png')`] : null,
      [`ex: square inside`] : null,
      [`ex: url('bullet.png') inside`] : null,
      [`ex: square url('bullet.png') inside`] : null,
   },
   listStyleImage : {
      inherit : 'inherit',
      initial : 'initial',
      none : 'none',
      unset : 'unset',
      [`ex: url('bullet.png')`] : null,
   },
   listStylePosition : {
      inherit : 'inherit',
      inside : 'inside',
      outside : 'outside',
   },
   listStyleType : {
      armenian : 'armenian',
      circle : 'circle',
      decimal : 'decimal',
      decimalLeadingZero : 'decimal-leading-zero',
      disc : 'disc',
      georgian : 'georgian',
      inherit : 'inherit',
      initial : 'initial',
      lowerAlpha : 'lower-alpha',
      lowerGreek : 'lower-greek',
      lowerlatin : 'lower-latin',
      lowerRoman : 'lower-roman',
      none : 'none',
      square : 'square',
      unset : 'unset',
      upperAlpha : 'upper-alpha',
      upperLatin : 'upper-latin',
      upperRoman : 'upper-roman',
   },
   margin : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 10px`] : null,
      [`ex: 25%`] : null,
      [`ex: 10px 20px`] : null,
      [`ex: 10px 20px 30px`] : null,
      [`ex: 10px 20px 30px 40px`] : null,
   },
   marginBottom : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 10px`] : null,
      [`ex: 25%`] : null,
   },
   marginLeft : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 10px`] : null,
      [`ex: 25%`] : null,
   },
   marginRight : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 10px`] : null,
      [`ex: 25%`] : null,
   },
   marginTop : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 10px`] : null,
      [`ex: 25%`] : null,
   },
   maxHeight : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      none : 'none',
      unset : 'unset',
      [`ex: 600px`] : null,
      [`ex: 40%`] : null,
   },
   maxWidth : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      none : 'none',
      unset : 'unset',
      [`ex: 800px`] : null,
      [`ex: 80%`] : null,
   },
   minHeight : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 200px`] : null,
      [`ex: 10%`] : null,
   },
   minWidth : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 300px`] : null,
      [`ex: 50%`] : null,
   },
   opacity : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 0.3`] : null,
   },
   orphans : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 5`] : null,
   },
   outline : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 2px`] : null,
      [`ex: solid`] : null,
      [`ex: red`] : null,
      [`ex: 2px solid`] : null,
      [`ex: 2px red`] : null,
      [`ex: solid red`] : null,
      [`ex: 2px solid red`] : null,
   },
   outlineColor : {
      inherit : 'inherit',
      initial : 'initial',
      invert : 'invert',
      unset : 'unset',
      [`ex: red`] : null,
      [`ex: #ff8800`] : null,
      [`ex: rgb(255,120,0)`] : null,
      [`ex: rgba(255,120,0,0.5)`] : null,
   },
   outlineStyle : {
      dashed : 'dashed',
      dotted : 'dotted',
      double : 'double',
      groove : 'groove',
      inherit : 'inherit',
      initial : 'initial',
      inset : 'inset',
      none : 'none',
      outset : 'outset',
      ridge : 'ridge',
      solid : 'solid',
      unset : 'unset',
   },
   outlineWidth : {
      inherit : 'inherit',
      initial : 'initial',
      medium : 'medium',
      thick : 'thick',
      thin : 'thin',
      unset : 'unset',
      [`ex: 10px`] : null,
   },
   overflow : {
      auto : 'auto',
      hidden : 'hidden',
      inherit : 'inherit',
      initial : 'initial',
      scroll : 'scroll',
      unset : 'unset',
      visible : 'visible',
   },
   overflowX : {
      auto : 'auto',
      hidden : 'hidden',
      inherit : 'inherit',
      initial : 'initial',
      scroll : 'scroll',
      unset : 'unset',
      visible : 'visible',
   },
   overflowY : {
      auto : 'auto',
      hidden : 'hidden',
      inherit : 'inherit',
      initial : 'initial',
      scroll : 'scroll',
      unset : 'unset',
      visible : 'visible',
   },
   padding : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 10px`] : null,
      [`ex: 25%`] : null,
      [`ex: 10px 20px`] : null,
      [`ex: 10px 20px 30px`] : null,
      [`ex: 10px 20px 30px 40px`] : null,
   },
   paddingBottom : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 10px`] : null,
      [`ex: 25%`] : null,
   },
   paddingLeft : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 10px`] : null,
      [`ex: 25%`] : null,
   },
   paddingRight : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 10px`] : null,
      [`ex: 25%`] : null,
   },
   paddingTop : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 10px`] : null,
      [`ex: 25%`] : null,
   },
   pageBreak : {
      always : 'always',
      auto : 'auto',
      avoid : 'avoid',
      inherit : 'inherit',
      initial : 'initial',
      left : 'left',
      right : 'right',
      unset : 'unset',
   },
   pageBreakInside : {
      auto : 'auto',
      avoid : 'avoid',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
   },
   position : {
      absolute : 'absolute',
      fixed : 'fixed',
      inherit : 'inherit',
      initial : 'initial',
      relative : 'relative',
      static : 'static',
      unset : 'unset',
   },
   quotes : {
      inherit : 'inherit',
      initial : 'initial',
      none : 'none',
      unset : 'unset',
      [`ex: '<<' '>>'`] : null,
      [`ex: '<<' '>>' '<' '>'`] : null,
   },
   right : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 100px`] : null,
      [`ex: 50%`] : null,
   },
   tableLayout : {
      auto : 'auto',
      fixed : 'fixed',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
   },
   textAlign : {
      center : 'center',
      inherit : 'inherit',
      initial : 'initial',
      justify : 'justify',
      left : 'left',
      right : 'right',
      unset : 'unset',
   },
   textDecoration : {
      none : 'none',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: underline`] : null,
      [`ex: overline dotted`] : null,
      [`ex: line-through red`] : null,
      [`ex: underline double #06c`] : null,
   },
   textDecorationLine : {
      inherit : 'inherit',
      initial : 'initial',
      lineThrough : 'line-through',
      none : 'none',
      overline : 'overline',
      underline : 'underline',
      unset : 'unset',
   },
   textDecorationStyle : {
      dashed : 'dashed',
      dotted : 'dotted',
      double : 'double',
      inherit : 'inherit',
      initial : 'initial',
      solid : 'solid',
      unset : 'unset',
      wavy : 'wavy',
   },
   textIndent : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 1em`] : null,
      [`ex: 10%`] : null,
   },
   textTransform : {
      capitalize : 'capitalize',
      inherit : 'inherit',
      lowercase : 'lowercase',
      none : 'none',
      uppercase : 'uppercase',
   },
   top : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 100px`] : null,
      [`ex: 50%`] : null,
   },
   transform : {
      inherit : 'inherit',
      initial : 'initial',
      none : 'none',
      unset : 'unset',
      [`ex: rotate(-45deg)`] : null,
      [`ex: skewX(20deg)`] : null,
      [`ex: skewY(20deg)`] : null,
      [`ex: skew(20deg, 40deg)`] : null,
      [`ex: scaleX(2)`] : null,
      [`ex: scaleY(0.5)`] : null,
      [`ex: scale(2)`] : null,
      [`ex: scale(2, 0.5)`] : null,
      [`ex: translateX(100px)`] : null,
      [`ex: translateY(-2em)`] : null,
      [`ex: translate(100px)`] : null,
      [`ex: translate(100px, -2em)`] : null,
      [`ex: matrix(2.6, -0.2, 1.9, 0.9, 205, -48.7)`] : null,
      [`ex: rotate3d(1, -1, 1, 45deg)`] : null,
      [`ex: rotateX(45deg)`] : null,
      [`ex: rotatey(50grad)`] : null,
      [`ex: rotateZ(1rad)`] : null,
      [`ex: scale3d(1, 2, 3)`] : null,
      [`ex: scaleZ(3)`] : null,
      [`ex: translate3d(10px, 20px, 30px)`] : null,
      [`ex: translateZ(30px)`] : null,
      [`ex: matrix3d(0.8, 0.3, 0.5, 0, -1, 1.6, 0.6, 0, -0.9, -1.5, 2.4, 0, -40.1, -10.2, 89.9, 1)`] : null,
      [`ex: perspective(500px)`] : null,
   },
   transformOrigin : {
      bottom : 'bottom',
      center : 'center',
      inherit : 'inherit',
      initial : 'initial',
      left : 'left',
      right : 'right',
      top : 'top',
      unset : 'unset',
      [`ex: 100px`] : null,
      [`ex: 25%`] : null,
      [`ex: 100px 25%`] : null,
      [`ex: 50px bottom 25px`] : null,
   },
   transition : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: color .3s linear 1s`] : null,
   },
   transitionDelay : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 1s`] : null,
      [`ex: .3s, 1s, 5s`] : null,
   },
   transitionDuration : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: .3s`] : null,
      [`ex: .3s, 1s, 5s`] : null,
   },
   transitionProperty : {
      all : 'all',
      none : 'none',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: color`] : null,
      [`ex: color, background, opacity`] : null,
   },
   transitionTimingFunction : {
      ease : 'ease',
      easeIn : 'ease-in',
      easeInOut : 'ease-in-out',
      easeOut : 'ease-out',
      inherit : 'inherit',
      initial : 'initial',
      linear : 'linear',
      stepEnd : 'step-end',
      stepStart : 'step-start',
      unset : 'unset',
      [`ex: cubic-bezier(0.5,1.5,0.5,-0.5)`] : null,
      [`ex: steps(5,end)`] : null,
   },
   unicodeBidi : {
      bidiOverride : 'bidi-override',
      embed : 'embed',
      inherit : 'inherit',
      initial : 'initial',
      normal : 'normal',
      unset : 'unset',
   },
   verticalAlign : {
      baseline : 'baseline',
      bottom : 'bottom',
      inherit : 'inherit',
      initial : 'initial',
      middle : 'middle',
      sub : 'sub',
      super : 'super',
      textBottom : 'text-bottom',
      textTop : 'text-top',
      top : 'top',
      unset : 'unset',
      [`ex: 5px`] : null,
      [`ex: 10%`] : null,
   },
   visibility : {
      visible : 'visible',
      hidden : 'hidden',
      collapse : 'collapse',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
   },
   whiteSpace : {
      inherit : 'inherit',
      initial : 'initial',
      normal : 'normal',
      noWrap : 'nowrap',
      pre : 'pre',
      preLine : 'pre-line',
      preWrap : 'pre-wrap',
      unset : 'unset',
   },
   widows : {
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 1`] : null,
   },
   width : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 600px`] : null,
      [`ex: 25%`] : null,
   },
   wordSpacing : {
      inherit : 'inherit',
      initial : 'initial',
      normal : 'normal',
      unset : 'unset',
      [`ex: 10px`] : null,
   },
   zIndex : {
      auto : 'auto',
      inherit : 'inherit',
      initial : 'initial',
      unset : 'unset',
      [`ex: 3`] : null,
   },
};