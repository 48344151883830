export const the = {
   empty: {
      'function': () => {
      },
   },
   method: {
      get: 'get',
      post: 'post',
      put: 'put',
   },
}